<template>
  <p>Data bank</p>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
  @import '~styles/components/settings/_data-bank-tab.scss';
</style>
